<template>
  <v-container fluid grey darken-4 white--text class="pa-0">
    <v-img
      alt="product foto"
      height="200"
      width="100vw"
      src="../assets/zep-example-product.jpeg"
    />
    <v-container class="py-4">
      <div class="text-overline">
        {{ product.type }}
      </div>
      <div class="text-h2 font-weight-medium">
        {{ product.title }}
      </div>
    </v-container>

    <v-container>
      <div class="mb-12 text-body-1">
        {{ product.shortDesc }}
      </div>
    </v-container>

    <v-container class="py-10 px-16" fluid grey darken-3>
      <v-row justify="center" align="center">
        <v-col cols="4">
          <v-row justify="center" align="center" class="text-h4 font-weight-bold">
            {{ product.bitterness }} IBU
          </v-row>
          <v-row justify="center" align="center" class="grey--text text-overline">
            Bittere
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row justify="center" align="center" class="text-h4 font-weight-bold">
            {{ product.color }} EBC
          </v-row>
          <v-row justify="center" align="center" class="grey--text text-overline">
            Farbe
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-row justify="center" align="center" class="text-h4 font-weight-bold">
            {{ product.alcohol }}%vol
          </v-row>
          <v-row justify="center" align="center"  class="grey--text text-overline">
            Alkohol
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="pa-12">
          <v-img
            alt="product image"
            src="../assets/VisitenkarteRückseite.png"
            contain
            height="40vh"
          />
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="pa-16 text-body-1">
            {{ product.desc }}
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return{
      product: Object
    }
  },
  mounted() {
    var url = "https://apizep.herokuapp.com/products/productItems/" + this.$route.params.id;
    axios.get(url).then(response => {
      this.product = response.data;
    });
  }
};
</script>

<style></style>
